/* 列表页面 */
// 初始化查询条件选择框数据
const getInitInfoURL = `${API_CONFIG.butlerBaseURL}customerManagement/getInitInfo`
// 查询列表
const getCustomerListURL = `${API_CONFIG.butlerBaseURL}customerManagement/getCustomerList`
// 删除列表数据
const deleteURL = `${API_CONFIG.butlerBaseURL}customerManagement/delete`

/* 表单页面 */
// 表单下拉框数据
const getDetailInitInfoURL = `${API_CONFIG.butlerBaseURL}customerManagement/getDetailInitInfo`
// 详情
const getDetailRecordInfoURL = `${API_CONFIG.butlerBaseURL}customerManagement/getDetailRecordInfo`
//  保存
const saveCustomerInfoURL = `${API_CONFIG.butlerBaseURL}customerManagement/saveCustomerInfo`
// 上级客户
const getSuperiorCustomerListURL = `${API_CONFIG.butlerBaseURL}customerManagement/getSuperiorCustomerList`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`

export {
  saveCustomerInfoURL,
  getInitInfoURL,
  getCustomerListURL,
  deleteURL,
  getDetailRecordInfoURL,
  getDetailInitInfoURL,
  getSuperiorCustomerListURL,
  uploadURL
}
