import { mapHelper } from 'common/utils'

// 资产状态
const assetStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '停用',
    value: 1
  }
]

const {
  map: assetStatusMap,
  setOps: assetStatusOps
} = mapHelper.setMap(assetStatus)

export {
  assetStatusMap,
  assetStatusOps
}
