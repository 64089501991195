var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerManageList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          exportMethod: _vm.exportMethod,
          searchParams: _vm.searchParams,
          isMultiSelect: false,
          headers: _vm.headers,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "客户名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "客户编号" },
                  model: {
                    value: _vm.searchParams.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "number", $$v)
                    },
                    expression: "searchParams.number",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "客户类型", options: _vm.typeOps },
                  model: {
                    value: _vm.searchParams.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "type", $$v)
                    },
                    expression: "searchParams.type",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所属公司" },
                  model: {
                    value: _vm.searchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orgName", $$v)
                    },
                    expression: "searchParams.orgName",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "合作状态",
                    options: _vm.cooperatingStateOps,
                  },
                  model: {
                    value: _vm.searchParams.cooperatingState,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "cooperatingState", $$v)
                    },
                    expression: "searchParams.cooperatingState",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "客户等级", options: _vm.gradeOps },
                  model: {
                    value: _vm.searchParams.grade,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "grade", $$v)
                    },
                    expression: "searchParams.grade",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "负责人" },
                  model: {
                    value: _vm.searchParams.director,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "director", $$v)
                    },
                    expression: "searchParams.director",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    type: "rangedatetimer",
                    label: "创建日期",
                    format: "YYYY-MM-DD HH:mm:ss",
                    startTime: _vm.searchParams.createDateStart,
                    endTime: _vm.searchParams.createDateEnd,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createDateStart",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createDateStart",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "createDateEnd", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "createDateEnd", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "edit" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "删除", type: "text", permission: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.remove(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }