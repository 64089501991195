<!--
 * @Description:  客户-客户管理
 * @Author: 马俊
 * @Date: 2021-1-8 09:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="customerManageList-wrapper">
    <list
      ref="list"
      :exportPermission="'export'"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>

      <template #searchSlot>
        <v-input label="客户名称" v-model="searchParams.name" />
        <v-input label="客户编号" v-model="searchParams.number" />
        <v-select label="客户类型" v-model="searchParams.type" :options="typeOps"/>
        <v-input label="所属公司" v-model="searchParams.orgName" />
        <v-select label="合作状态" v-model="searchParams.cooperatingState" :options="cooperatingStateOps"/>
        <v-select label="客户等级" v-model="searchParams.grade" :options="gradeOps"/>
        <v-input label="负责人" v-model="searchParams.director" />
        <v-datepicker
          type="rangedatetimer"
          label="创建日期"
          format="YYYY-MM-DD HH:mm:ss"
          :startTime.sync="searchParams.createDateStart"
          :endTime.sync="searchParams.createDateEnd"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="edit" @click="edit(scope.row)" />
        <v-button text="删除" type="text" permission="delete" @click="remove(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import {
  getInitInfoURL,
  getCustomerListURL,
  deleteURL
} from './api'
import {
} from './map'
import { mapHelper } from 'common/utils'

export default {
  name: 'customerManageList',
  data () {
    return {
      searchUrl: getCustomerListURL,
      exportUrl: 'xx',
      exportMethod: 'get',
      typeOps: [],
      cooperatingStateOps: [],
      gradeOps: [],
      searchParams: {
        name: '',
        number: '',
        type: undefined,
        orgName: '',
        cooperatingState: undefined,
        grade: undefined,
        director: '',
        createDateStart: '',
        createDateEnd: ''
      },
      headers: [
        {
          prop: 'name',
          label: '客户名称'
        },
        {
          prop: 'number',
          label: '客户编号'
        },
        {
          prop: 'type',
          label: '客户类型'
        },
        {
          prop: 'orgName',
          label: '所属公司'
        },
        {
          prop: 'cooperatingState',
          label: '合作状态'
        },
        {
          prop: 'grade',
          label: '客户等级'
        },
        {
          prop: 'director',
          label: '负责人'
        },
        {
          prop: 'createDate',
          label: '创建日期'
        }
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
    this.getInitInfo()
    this.$setBreadcrumb('客户管理')
  },
  mounted () {
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    create () {
      this.$router.push({
        name: 'customerManageForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'customerManageForm',
        query: {
          id: row.id
        }
      })
    },
    async remove (row) {
      let isConfirm = await this.$confirm('删除操作后无法恢复数据，确定要删除吗？')
      if (isConfirm) {
        this.$axios.delete(`${deleteURL}/${row.id}`).then(res => {
          if (res.status === 100) {
            this.$refs.list.searchData()
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
      }
    },
    getInitInfo () {
      this.$axios.get(getInitInfoURL).then((res) => {
        console.log(res, 'res--')
        if (res.status === 100) {
          this.cooperatingStateOps = this.dealData(res.data.customerCooperatingStateList)
          this.gradeOps = this.dealData(res.data.customerGradeList)
          this.typeOps = this.dealData(res.data.customerTypeList)
        }
      })
    },
    dealData (arr) { // 处理数据使符合下拉框格式
      let list = []
      arr.forEach(item => {
        list.push({
          text: item,
          value: item
        })
      })
      const { setOps } = mapHelper.setMap(list)
      return list.length > 0 ? setOps(1) : list
    }
  }
}
</script>

<style lang="scss" scoped>
.customerManageList-wrapper {
}
</style>
